import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const TeacherPage = ({ data, location, pageContext}) => {


  const teacher = data && data.teacherInfo && data.teacherInfo.edges[0].node.data;
  const classes = data.classInfo.edges.map(x => x.node.data);

  if(!teacher.Headshot){
    return null;
  }
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const name = teacher.Name;
  const description = teacher.Description;
  const imageSource = teacher.Headshot[0].url;

  console.log(teacher)

  return (
    <Layout location={location} title={siteTitle} backTo={"See All Teachers In " + teacher.Name__from_Regions_[0]} backToLink={"/teachers/"+teacher.region_slug__from_Regions_[0]} noEmailForm={true}>
      <Seo
        title={"Dance classes with " + name}
        description={description}
        image={imageSource}
      />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{name}</h1>
        </header>



        <img
          alt="Headshot"
          src={imageSource}
          style={{maxWidth: "100%"}}
        />

        <section
          itemProp="articleBody">

          <h4>Styles</h4>
          <div>
          {teacher.Styles.map(danceStyle => {
            return <span key={danceStyle} className="dance-style">{danceStyle}</span>
          })}
          </div>

          <h4>About {name}</h4>

          <div className="pre-line" dangerouslySetInnerHTML={{__html: teacher.About}}></div>
          {teacher.Headshot && teacher.Headshot[1] && <img
            alt="Headshot"
            src={teacher.Headshot[1].url}
            style={{maxWidth: "100%", marginTop: 10}}
        />}

          <h4>Classes</h4>

          {(teacher.DiscountStatus == "Live") &&  <div id="mc_embed_signup" className="discounted">
            <form action="https://crewcraftsmedia.us14.list-manage.com/subscribe/post?u=c5a86ad82aa6ae00b36502df0&amp;id=188a1da9a5" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
              <p id="email-header"><label htmlFor="mce-EMAIL"><span className="discount">{teacher.Discount_Amount} Discount Code Available</span><br/>Subscribe to get Social Dance Today in your inbox, and receive a discount code for {teacher.Discount_Amount} off your first class with {teacher.Name}.</label></p>
            <div class="indicates-required"><span className="asterisk">*</span> indicates required</div>
            <div className="mc-field-group">
              <label for="mce-EMAIL">Email Address  <span className="asterisk">*</span>
            </label>
              <input type="email" defaultValue="" name="EMAIL" required className="required email" id="mce-EMAIL" />
            </div>
            <div className="mc-field-group">
              <label for="mce-FNAME">First Name <span className="asterisk">*</span></label>
              <input type="text" defaultValue="" name="FNAME" required className="required chimp-input" id="mce-FNAME"/>
            </div>
            <div className="mc-field-group">
              <label for="mce-LNAME">Last Name <span className="asterisk">*</span></label>
              <input type="text" defaultValue="" name="LNAME" required className="required  chimp-input" id="mce-LNAME" />
            </div>
            <div hidden="true"><input type="hidden" name="tags" value={teacher.MailchimpTag}/></div>
              <div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                <div className="response" id="mce-success-response" style={{display:"none"}}></div>
              </div>
                <div style={{position: "absolute", left: -5000, ariaHidden: true}}><input type="text" name="b_c5a86ad82aa6ae00b36502df0_188a1da9a5" tabindex="-1" value=""/></div>
                    <div className="optionalParent">
                        <div className="clear foot">
                            <input type="submit" value={"Subscribe and Save " + teacher.Discount_Amount} name="subscribe" id="mc-embedded-subscribe" className="button"/>

                        </div>
                    </div>
                </div>
            </form>
          </div>}

          <div>
          {classes.map(thisClass => {
            return <div key={thisClass.RecordID} className="class-display">
              <h6>{thisClass.Name}</h6>
              <div className="pre-line">{thisClass.Schedule}</div>
              <div className="pre-line">{thisClass.Pricing}</div>
              <div className="pre-line">{thisClass.VenueName}, {thisClass.VenueAddress}</div>
              <br/>
              <div>{thisClass.Notes}</div>
            </div>

          })}
          </div>

          <h4>Contact</h4>
          <div className="pre-line">{teacher.Contact}</div>

          {(teacher.DiscountStatus == "Live")  && <div><br/>
          A discount code is available for this teacher (see above). After you subscribe and receive the discount code in your email, you can use this code for {teacher.Discount_Amount} off when you book your first class with {teacher.Name}.
          </div>}





          <br/>

        </section>

      </article>
    </Layout>
  )
}

export default TeacherPage

export const teacherQuery = graphql`
  query TeacherByID(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    teacherInfo: allAirtable(filter: {table: {eq: "Teachers"}, data: {RecordID: {eq: $id}}}) {
       edges {
        node {
          data {
           RecordID
           Name
           Description
           About
           Styles
           Pricing
           Contact
           MailchimpTag
           Discount_Amount
           Headshot {url}
           region_slug__from_Regions_
           Name__from_Regions_
          }
        }
       }
    },
    classInfo: allAirtable(filter: {table: {eq: "Classes"}, data: {Status: {eq: "Live"}, TeacherRecordID: {eq: $id}}}) {
       edges {
        node {
          data {
           Name
           Schedule
           Pricing
           Notes
           VenueName
           VenueAddress
           RecordID
          }
        }
       }
    },
  }
`
